import React,{useState} from 'react';
import page1 from "../assets/g-1.png";
import page2 from "../assets/g-2.png";
import page3 from "../assets/g-3.png";
import page4 from "../assets/g-4.png";

const Website = () => {
  const [flipped, setFlipped] = useState({});
  const handleFlip = (id) => {
    setFlipped(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };
const data=[
  {
    id:1,
    imgUrl:page1,
    title:"MobiBattle",
    subTitle:"The New age Esports Gaming platform",
    paras:[
    {  
      id:1,
      item:"Step into the world of MobiBattle, where gaming ascends to new heights, and the thrill is endless! Brace yourself for an unitemlleled gaming adventure that transcends imagination."},
    {
      id:2,
      item:"Prepare to be astounded by our cutting-edge platform, harnessing the latest technology for mind-boggling graphics and immersive gameplay. Our diverse collection, from heart-pounding eSports titles to soothing casual games, caters to every gamer's preference."},
    {
      id:3,
      item:"At MobiBattle, gaming isn't just about playing; it's about uniting, competing, and having an incredible time together."},
    {
      id:4,
      item:"Get set for a gaming odyssey like no other. MobiBattle is here to redefine your gaming experience."},
    {
      id:5,
      item:"Are you ready for the wow factor? Join us and discover gaming like never before!"}
    ]
  },
  {
    id:2,
    imgUrl:page2,
    title:"ExGames",
    subTitle:"The mobile engagement platform",
    paras:[
      {
        id:6,
        item:"Experience ExGAMES, your premier mobile entertainment platform offering a diverse collection of captivating games for all ages. From action-packed shooters to mind-bending puzzles, thrilling war games to high-speed car challenges, and intriguing hidden object adventures, ExGAMES has it all."},
      {
        id:7,
        item:"Our daily updated Trending Game section keeps you up-to-date with the latest gaming trends. Whether you're a casual gamer or a dedicated enthusiast, ExGAMES tailors an exceptional user experience to your gaming preferences."},
      {
        id:8,
        item:"Dive into the gaming universe with ExGAMES, elevating your adventure. Join us today to enjoy your favorite games, anytime, anywhere. Entertainment is at your fingertips."
  }]
  },
  {
    id:3,
    imgUrl:page3,
    title:"SwipeNWin",
    subTitle:"The unmatched quiz experiences",
    paras:[
      {
        id:9,
        item:"SwipeNWin delivers a captivating trivia experience through effortless swiping. Engage in thrilling quiz games by swiping right for yes or left for no. Dive into tempting questions and seize more chances to win daily, weekly, and monthly prizes."},
      {
        id:10,
        item:"What makes SwipeNWin exceptional? "},
      {
        id:11,
        item:"Its stunning interface presents questions with a countdown timer, accompanied by realistic sounds for added excitement. Winners rise on the leaderboard, fueling the competitive drive."},
      {
        id:12,
        item:"Join us for an unmatched quiz adventure at SwipeNWin!"}
    ]
},
  {
    id:4,
    imgUrl:page4,
    title:"Dream Travel",
    subTitle:"Your window to the world!",
    paras:[
      {
        id:13,
        item:"Welcome to Dream Travel, your ultimate gateway to a world of adventure!"},
      {
        id:14,
        item:"Dream Travel is more than just a service; it's a game-changer in the world of value-added services. We recognize that travel is an integral part of the human experience, from our earliest ancestors to today's wanderlust-driven millennials."},
     {
      id:15,
      item:"Participate weekly, accruing points throughout the month in our quiz, and here's the thrilling part: the user with the highest points at month's end wins an all-expense-paid trip to a top global tourist destination."},
     {
      id:16,
      item:"Prepare to embark on an unitemlleled journey with us."
}]
  }
]
  return (
    <main className="App pb-3">
    <section className="text-green-500 p-4 text-center text-3xl font-bold">
      Digital
    </section>
    <section className="w-full px-4 flex flex-col gap-3">
      {data.map((item) => (
        <div key={item.id} className={`flip-card ${flipped[item.id] ? 'flipped' : ''}`} onClick={() => handleFlip(item.id)}>
          <div className="flip-card-inner">
            <div className="flip-card-front p-4 rounded-3xl shadow-lg">
              <img src={item.imgUrl} alt={item.title} className="h-[90%] w-full object-contain bg-black/5 rounded-3xl" />
              <h1 className="text-lg text-green-500 font-semibold ">{item.title}</h1>
              <p className="">{item.subTitle}</p>
            </div>
            <div className="flip-card-back p-4 rounded-3xl shadow-lg">
              <div>
              {item.paras.map((para) => (
                <p key={para.id} className="mb-2">{para.item}</p>
                ))}
              </div>
                <div className='flex gap-3'>
               {[1,2,3].map((_,index)=>
               {
                return <div key={index} className='p-3 bg-green-500 rounded-full mb-24 '></div>
               })}
            </div>
            </div>
          
          </div>
        </div>
      ))}
    </section>
  </main>
  );
};

export default Website;
