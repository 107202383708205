import React, { useState, useEffect, lazy } from 'react'
import Iframe from './Iframe'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const [loading, setLoading] = useState(false)
  const [iframeSrc, setIframeSrc] = useState("")
  const navigate=useNavigate()
  const [showIframe, setShowIframe] = useState(false)
  const handleClick = () => {
    setLoading(true);
    setShowIframe(true);
    navigate('/')
    // setIframeSrc("https://blackngreen.com/wappromotions.php")
  };


  useEffect(() => {
    let timer;
    if (showIframe && loading) {
      timer = setTimeout(() => {
        setLoading(false)
      }, 2000);
    }
    return () => {
      clearTimeout(timer)
    }
  }, [showIframe,loading])
  return (
    <div className='App h-screen flex  items-center flex-col justify-center'>
      {/* <h1 className='text-3xl font-extrabold py-4'>Oops!</h1>
        <p className='text-xl pb-4'>Home Not found</p>
        <button className='bg-red-500 rounded-md p-2 text-white' onClick={handleClick}>Go to Home Page</button> */}
      {loading && <div className='z-10 bg-black/30 fixed top-0 h-screen  w-full max-w-[500px] flex justify-center items-center'>
        <div className="loader">
        </div>
      </div>
      }
      {(iframeSrc && showIframe) ? <Iframe iframeSrc={iframeSrc} /> :
        <>
          <h1 className='text-3xl font-extrabold py-4'>Oops!</h1>
          <p className='text-xl pb-4'>Page Not found</p>
          <button className='bg-red-500 rounded-md p-2 text-white' onClick={handleClick}>Go to Home Page</button></>
      }
    </div>
  )
}

export default NotFound