import React from 'react'

const Iframe = ({iframeSrc}) => {
  return (
    <div className='w-full h-screen'>  
        {iframeSrc && (
        <iframe
          src={iframeSrc}
          title="wap promos"
          style={{ width: '100%', height: '100vh', border: '1px solid #ccc' }}
          sandbox="allow-same-origin allow-scripts"
        ></iframe>
      )}

    </div>
  )
}

export default Iframe