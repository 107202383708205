import React, { useState, useEffect, useRef } from 'react';

const HeaderEnrichmentComponent = () => {
  const [msisdn, setMsisdn] = useState(null);
  const iframeRef = useRef(null);

  // Function to handle message received from the iframe
  const handleMessage = (event) => {
    // Ensure the message is from the iframe and handle it accordingly
    if (event.source === iframeRef.current.contentWindow) {
      // Extract the MSISDN or other relevant data from the message payload
      const receivedMsisdn = event.data.msisdn;
      console.log('Received MSISDN:', receivedMsisdn);

      // Store the received MSISDN in the component's state
      setMsisdn(receivedMsisdn);

      // Close the iframe
      iframeRef.current.style.display = 'none'; // or remove the iframe from the DOM
    }
  };

  // Add event listener for messages received from the iframe
  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <>
      {msisdn ? (
        <div>
          MSISDN Received: {msisdn}
          {/* Continue with your code flow here */}
        </div>
      ) : (
        <iframe
          ref={iframeRef}
          src="http://52.36.94.173/headercheck/msisdn.php"
          title="Header Enrichment Iframe"
          style={{ display: 'none' }} // Hide the iframe initially
        />
      )}
    </>
  );
};

export default HeaderEnrichmentComponent;