import './App.css';
import { useEffect,useState } from 'react';
import HeadersDataPage from './components/HeadersDataPage';
import { BrowserRouter as Router,Routes,Route,useNavigate } from 'react-router-dom';
import NotFound from './components/NotFound';
import Website from './components/Website';


function App() {
  ;
  return (
  <Router>
    <Routes>
     <Route path="/" element={<Website />} />
      <Route path="/he" element={<HeadersDataPage/>}/>
      <Route path="*" element={<NotFound/>}/>
    </Routes>
  </Router>
  );
}

export default App;
